// MobileMenu.js
import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

const MobileMenu = ({ toggleMobileMenu }) => {
    return (
        <div className="mobile-menu">
            <nav>
                <ul>
                    <li>
                        <NavLink to="/" end className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/our-products" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                            Products
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/about-us" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                            About Us
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/blogs" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                            Blogs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact-us" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                            Contact Us
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/3d-order" className={({ isActive }) => (isActive ? "active-link" : "")} onClick={toggleMobileMenu}>
                            3D Order
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default MobileMenu;
