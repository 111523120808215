// Import neccessary packages
import { NavLink } from "react-router-dom";
import { useState } from "react";

// Import components
import MobileMenu from "../mobile-menu/layout";

// Import styles and assets
import "./style.css";
import "./responsive.css";
import "./animation.css";
import logo from "../../assets/website-logo.jpg";
import menuIcon from "../../assets/light-mode/menu-icon.svg";
import closeIcon from "../../assets/light-mode/close-menu.svg";

// React header component function
export default function Header() {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://localhost:5000/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                alert('Email sent successfully!');
                setShowForm(false);
                setFormData({ name: '', email: '', message: '' });
            } else {
                alert('Failed to send email');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to send email');
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const navItems = [
        { name: "Home", path: "/" },
        { name: "About Us", path: "/about-us" },
        { name: "Our Products", path: "/our-products" },
        { name: "3D Order", path: "/3d-order" },
        { name: "Contact Us", path: "/contact-us" },
        { name: "Blogs", path: "/blogs" }
    ];

    return (
        <>
            <header className="header-section" id="header-section">
                <nav className="header-logo-title" id="header-logo-title">
                    <NavLink to="/" end className={({ isActive }) => (isActive ? "active-link" : "")}>
                        <img src={logo} alt="Website logo" className="header-logo" id="header-logo" />
                        <div className="header-title" id="header-title">
                            <b>Sudarshan</b> <span style={{
                                fontSize: 12
                            }}>Aerial Solutions Pvt Ltd.</span>
                        </div>
                    </NavLink>
                </nav>
                <nav className="header-options" id="header-options">
                    <ul>
                        {navItems.map((item, index) => (
                            <li key={index}><NavLink to={item.path} className={({ isActive }) => (isActive ? "active-link" : "")}>{item.name}</NavLink></li>
                        ))}
                    </ul>
                </nav>
                <div className="header-button-container" id="header-button-container">
                    <button className="get-in-touch-button" onClick={() => setShowForm(true)}>
                        Get in touch
                    </button>
                </div>
                <div className="options-container" id="options-container">
                    <img
                        src={isMobileMenuOpen ? closeIcon : menuIcon}
                        className="show-menu-icon"
                        onClick={toggleMobileMenu}
                        alt={isMobileMenuOpen ? "Close menu" : "Open menu"}
                    />
                </div>
            </header>

            {showForm && (
                <div className="form-overlay">
                    <div className="form-container">
                        <form onSubmit={handleSubmit}>
                            <button 
                                type="button" 
                                className="close-form"
                                onClick={() => setShowForm(false)}
                            >
                                ×
                            </button>
                            <h2>Contact Us</h2>
                            <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            <textarea
                                name="message"
                                placeholder="Your Message"
                                value={formData.message}
                                onChange={handleInputChange}
                                required
                            ></textarea>
                            <button type="submit">Send Message</button>
                        </form>
                    </div>
                </div>
            )}
            
            {isMobileMenuOpen && <MobileMenu toggleMobileMenu={toggleMobileMenu} />}
        </>
    );
}
