import React, { useState } from 'react';
import '../styles/3DOrder.css';

const ThreeDOrder = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    material: '',
    size: '',
    color: '',
    quantity: 1,
    instructions: '',
  });
  const [stlFile, setStlFile] = useState(null);
  const [referenceImage, setReferenceImage] = useState(null);
  const [submitStatus, setSubmitStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileChange = (e, type) => {
    if (type === 'stl') {
      setStlFile(e.target.files[0]);
    } else {
      setReferenceImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('sending');

    try {
      // Create FormData object
      const formDataToSend = new FormData();

      // Manually append each form field
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('phone', formData.phone);
      formDataToSend.append('address', formData.address);
      formDataToSend.append('material', formData.material);
      formDataToSend.append('size', formData.size);
      formDataToSend.append('color', formData.color);
      formDataToSend.append('quantity', formData.quantity);
      formDataToSend.append('instructions', formData.instructions);

      // Append files with specific names
      if (stlFile) {
        formDataToSend.append('stlFile', stlFile, stlFile.name);
      }

      if (referenceImage) {
        formDataToSend.append('referenceImage', referenceImage, referenceImage.name);
      }

      const response = await fetch('http://localhost:3001/submit-order', {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      // Handle large files
      if (data.largeFiles) {
        alert(`Some files are too large to send via email. Please upload ${data.largeFiles.count} file(s) to our Google Drive:\n\n${data.largeFiles.uploadLink}`);
      }

      setSubmitStatus('success');
      alert('Order submitted successfully!' + 
        (data.largeFiles ? '\n\nPlease check your email for instructions on uploading large files.' : ''));

      // Reset form
      setFormData({
        name: '',
        email: '',
        phone: '',
        address: '',
        material: '',
        size: '',
        color: '',
        quantity: 1,
        instructions: '',
      });
      setStlFile(null);
      setReferenceImage(null);

      // Reset file input fields
      const fileInputs = document.querySelectorAll('input[type="file"]');
      fileInputs.forEach(input => input.value = '');
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
      alert(`Error submitting form: ${error.message}`);
    }
  };

  return (
    <div className="three-d-order-container">
      <h1>3D Printing Order Form</h1>
      
      <form onSubmit={handleSubmit} className="order-form">
        <div className="form-section">
          <h2>Personal Details</h2>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          <textarea
            name="address"
            placeholder="Delivery Address"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-section">
          <h2>Product Details</h2>
          <select
            name="material"
            value={formData.material}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Material</option>
            <option value="pla">PLA</option>
            <option value="abs">ABS</option>
            <option value="petg">PETG</option>
          </select>
          
          <input
            type="text"
            name="size"
            placeholder="Size (dimensions in mm)"
            value={formData.size}
            onChange={handleInputChange}
            required
          />
          
          <input
            type="text"
            name="color"
            placeholder="Color"
            value={formData.color}
            onChange={handleInputChange}
            required
          />
          
          <input
            type="number"
            name="quantity"
            placeholder="Quantity"
            min="1"
            value={formData.quantity}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-section">
          <h2>Additional Instructions</h2>
          <textarea
            name="instructions"
            placeholder="Any special instructions..."
            value={formData.instructions}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-section">
          <h2>File Upload</h2>
          <div className="file-upload">
            <label>
              STL File (Required):
              <input
                type="file"
                accept=".stl"
                onChange={(e) => handleFileChange(e, 'stl')}
                required
              />
            </label>
            {stlFile && <p className="file-name">Selected: {stlFile.name}</p>}
          </div>
          
          <div className="file-upload">
            <label>
              Reference Image (Optional):
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, 'image')}
              />
            </label>
            {referenceImage && <p className="file-name">Selected: {referenceImage.name}</p>}
          </div>
        </div>

        <button 
          type="submit" 
          className="submit-button"
          disabled={submitStatus === 'sending'}
        >
          {submitStatus === 'sending' ? 'Sending...' : 'Submit Order'}
        </button>

        {submitStatus === 'success' && (
          <div className="success-message">
            Order submitted successfully!
          </div>
        )}

        {submitStatus === 'error' && (
          <div className="error-message">
            Error submitting form. Please try again.
          </div>
        )}
      </form>
    </div>
  );
};

export default ThreeDOrder; 